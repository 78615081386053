import angularModule from '../front_royal_module';

angularModule.controller('ParamsPassthroughCtrl', [
    '$scope',
    '$route',
    '$routeParams',
    // eslint-disable-next-line func-names
    function ($scope, $route, $routeParams) {
        $scope.directive = $route.current.directive;
        if (!$scope.directive) {
            throw new Error('No directive defined in route.');
        }

        // generate static html for compile
        let tag = `<${$scope.directive} `;
        angular.forEach($routeParams, (val, key) => {
            tag = `${tag + key}="${val}"`;
        });
        tag += ' />';
        $scope.html = tag;
    },
]);
